const apiUrl = (`${process.env.REACT_APP_BASE_URL}/yuni/responses`);
;

export const fetchResponses = async () => {
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch responses');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching responses:', error);
    throw error;
  }
};

export const createResponse = async (responseDetails) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/student/create-responses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(responseDetails)
    });

    const data = await response.json(); 
    if (!response.ok) {
      throw new Error(data.message || 'Failed to create response');
    }
    return { success: true, data };
  } catch (error) {
    console.error("Error in createResponse:", error);
    return { success: false, message: error.message || 'Failed to create response' };
  }
};