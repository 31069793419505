import React, { useState, useEffect } from 'react';
import SuccessNotification from '../../components/common-ui/SuccessNotification';
import FailureNotification from '../../components/common-ui/FailureNotification';
import FailureNotificationWithOptions from '../../components/common-ui/FailureNotificationWithOptions';
import { createResponse } from '../../services/responses.service';
import { evaluateAnswer } from '../../services/gpt.service';
import { STORY_LAZY_DUCK, STORY_KINGS_CAKE, POINTS_OPEN_ENDED_CORRECT } from '../../utils/constants';
import { fetchPoints, updatePoints } from '../../services/users.service';

const useUserPoints = (userId) => {
  const [points, setPoints] = useState(0);
  useEffect(() => {
    const getUserPoints = async () => {
      try {
        const data = await fetchPoints(userId);
        setPoints(data.level);
        console.log(`Current Points: ${data.level}`);
      } catch (error) {
        console.error('Failed to fetch user points:', error);
      }
    };
    getUserPoints();
  }, [userId]);
  return points;
};

const getContext = (storyId) => {
  switch (storyId) {
    case '653e087da69abc09ffabca5a':
      return STORY_KINGS_CAKE;
    case '652e66b3a11c62a7a4b89a8d':
      return STORY_LAZY_DUCK;
    default:
      return '';
  }
};

const OpenEndedQuestion = ({ question, onNextQuestion, userId, storyId }) => {
  const [answer, setAnswer] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const points = useUserPoints(userId);

  const handleSubmit = async () => {
    const trimmedAnswer = answer.trim();
    if (trimmedAnswer.length === 0) {
      setNotificationText("Please enter an answer before submitting.");
      setNotificationType('failure');
      setShowNotification(true);
      return;
    }

    const context = getContext(storyId);
    const evaluationDetails = { context, question: question.question, userAnswer: trimmedAnswer };
    console.log(evaluationDetails);

    try {
      const evaluationResponse = await evaluateAnswer(evaluationDetails);
      console.log(evaluationResponse);

      if (evaluationResponse.data.result === 'correct') {
        const newPoints = points + POINTS_OPEN_ENDED_CORRECT;
        console.log(`Earned Points: ${POINTS_OPEN_ENDED_CORRECT}, New Points: ${newPoints}`);
        try {
          await updatePoints(userId, newPoints);
          setNotificationText(`Great work! Your answer is correct and you earned +${POINTS_OPEN_ENDED_CORRECT} points!`);
          setNotificationType('success');
          setShowNotification(true);
          setAnswer("");
        } catch (error) {
          console.error('Failed to update points:', error);
        }
      } else {
        setNotificationText("I don't understand your answer. Would you like to retry or submit to the teacher?");
        setNotificationType('failureWithOptions');
        setShowNotification(true);
      }
    } catch (error) {
      console.error("Error evaluating answer:", error);
    }
  };

  const handleRedo = () => {
    setAnswer("");
    setShowNotification(false);
  };

  const handleSubmitToTeacher = async () => {
    try {
      const data = { userId, questionId: question._id, answer: answer.trim(), subject: question.subject };
      const response = await createResponse(data);
      console.log(response);
      setNotificationText("Your answer has been submitted to the teacher.");
      setNotificationType('success');
      setShowNotification(true);
      setAnswer("");
      onNextQuestion();
    } catch (error) {
      console.error("Error submitting answer:", error);
      setNotificationText("There was an error submitting your answer. Please try again.");
      setNotificationType('failure');
      setShowNotification(true);
    }
  };

  const closeNotification = () => {
    setShowNotification(false);
    if (notificationType === 'success') {
      onNextQuestion();
    }
  };

  return (
    <div className="question-container">
      <div className="question-title"><p>{question.question}</p></div>
      <textarea
        spellCheck="true"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder="Type your answer here..."
        className="answer-input"
        maxLength={200}
        required
      />
      {!showNotification && (
        <button onClick={handleSubmit} className="check-btn">Submit</button>
      )}
      {showNotification && notificationType === 'success' && (
        <SuccessNotification
          text={notificationText}
          points={POINTS_OPEN_ENDED_CORRECT}
          duration={3000}
          onClose={closeNotification}
        />
      )}
      {showNotification && notificationType === 'failure' && (
        <FailureNotification
          text={notificationText}
          onClose={closeNotification}
        />
      )}
      {showNotification && notificationType === 'failureWithOptions' && (
        <FailureNotificationWithOptions
          text={notificationText}
          onRetry={handleRedo}
          onSubmit={handleSubmitToTeacher}
        />
      )}
    </div>
  );
};

export default OpenEndedQuestion;
