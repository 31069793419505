import React from 'react';
import { Link } from 'react-router-dom';
import img652e66b3a11c62a7a4b89a8d from '../../components/common-ui/assets/stories/652e66b3a11c62a7a4b89a8d.png';
import img653e087da69abc09ffabca5a from '../../components/common-ui/assets/stories/653e087da69abc09ffabca5a.png';

const StoryCard = ({ story }) => {
  const imageMap = {
    '653e087da69abc09ffabca5a': img653e087da69abc09ffabca5a,
    '652e66b3a11c62a7a4b89a8d': img652e66b3a11c62a7a4b89a8d
  };

  const storyImagePath = imageMap[story._id] || require(`../../components/common-ui/assets/stories/default.jpg`).default;

  return (
    <div className="story-card">
      <img src={storyImagePath} alt={story.storyName} className="story-image" />
      <div className="story-details">
        <div className="story-title">{story.storyName}</div>
        <div className='story-button-details'>
        <Link to={`/questions/${story._id}`}>
          <button className="check-btn-story">Questions</button>
        </Link>
        <Link to={`/collection/${story._id}`}>
          <button className="check-btn-story">Collection</button>
        </Link>
        </div>
      </div>
    </div>
  );
};

export default StoryCard;
