import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { getCharactersByStoryId } from '../../services/character.service';
import { Link } from 'react-router-dom';


const CharacterModal = ({ isOpen, onRequestClose, storyId, count }) => {
  const [character, setCharacter] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCharacter = async () => {
      const result = await getCharactersByStoryId(storyId);
      if (result.success) {
        const unlockedCharacters = Math.floor(count / 10);
        if (unlockedCharacters > 0) {
          setCharacter(result.characters[unlockedCharacters - 1]);
        }
      } else {
        setError(result.message);
      }
    };

    fetchCharacter();
  }, [storyId, count]);

  const getSanitizedCharacterName = (name) => {
    return name.toLowerCase();
  };

  const getImageUrl = (characterName) => {
    const sanitizedCharacterName = getSanitizedCharacterName(characterName);
    const basePath = storyId === '653e087da69abc09ffabca5a' ? 'kings_cake' :
                     storyId === '652e66b3a11c62a7a4b89a8d' ? 'lazy_duck' :
                     'default';
    return `https://bsc2fast.github.io/hopu/images/${basePath}/${sanitizedCharacterName}.png`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Character Earned Notification"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="flex justify-center">
      </div>
      <div className="popup-message">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          character && (
            <>
              <p>Congratulations! You've earned a new character:</p>
              <div className="flex justify-center">
              <img src={getImageUrl(character.characterName)} alt={character.characterName} className="max-h-40" />
              </div>
            </>
          )
        )}
      </div>
      <div className="flex justify-center space-x-4">
        <button onClick={onRequestClose} className="modal-button success-btn">Okay</button>
        <Link to={`/collection/${storyId}`} className="modal-button success-btn">Let's See</Link>
      </div>
    </Modal>
  );
};

export default CharacterModal;
