// FailureNotification.js
import React from 'react';
import PropTypes from 'prop-types';
import bearImage from './assets/modal/rubear-sad.png'; 

const FailureNotification = ({ text, onClose }) => {
  return (
     <div className="notification-wrapper">
      <div className="notification-container failure">
        <div className="notification-content">
          <img src={bearImage} alt="Bear" className="notification-image" />
          <span className="notification-text">{text}</span>
        </div>
      </div>
      <button className="modal-button failure-btn" onClick={onClose}>Try again</button>
    </div>
  );
};

FailureNotification.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FailureNotification;
