import React, { useState, useEffect } from 'react';
import SuccessNotification from '../../components/common-ui/SuccessNotification';
import FailureNotification from '../../components/common-ui/FailureNotification';
import _ from 'lodash';
import { fetchPoints, updatePoints } from '../../services/users.service';
import { POINTS_FIRST_ATTEMPT_MCQ, POINTS_LATER_ATTEMPTS_MCQ } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import activityActions from '../../store/activityActions';

const shuffleArray = (array) => _.shuffle(array);

const MCQQuestion = ({ question, userId, onNextQuestion }) => {
  const dispatch = useDispatch();
  const levelPoints = useSelector((state) => state.activity.levelPoints);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [isCorrect, setIsCorrect] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [earnedPoints, setEarnedPoints] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [attemptCount, setAttemptCount] = useState(0);
  const [points, setPoints] = useState(0);

  useEffect(() => {
    setAnswers(shuffleArray([question.correctAnswer, ...question.wrongAnswers]));
  }, [question]);

  useEffect(() => {
    const getUserPoints = async () => {
      try {
        const data = await fetchPoints(userId);
        setPoints(data.level);
        console.log(`Current Points: ${data.level}`);
      } catch (error) {
        console.error('Failed to fetch user points:', error);
      }
    };

    getUserPoints();
  }, [userId]);

  const handleCheckAnswer = () => {
    setAttemptCount(attemptCount + 1);
    console.log(`Attempt ${attemptCount + 1}: Selected Answer - ${selectedAnswer}`);
    const correctness = selectedAnswer === question.correctAnswer;
    setIsCorrect(correctness);
    if (correctness) {
      const pointsToEarn = attemptCount === 1 ? POINTS_FIRST_ATTEMPT_MCQ : POINTS_LATER_ATTEMPTS_MCQ;
      setEarnedPoints(pointsToEarn);
      dispatch(activityActions.setLevelPoints({ data: levelPoints + pointsToEarn }))
      setNotificationText(`Great work! You gained +${pointsToEarn} points!`);
      setNotificationType('success');
    } else {
      setNotificationText("Oops, that's not right. Shall we try again?");
      setNotificationType('failure');
    }
    setShowNotification(true);
  };

  const handleNextQuestion = async () => {
    const newPoints = points + earnedPoints;

    console.log(`Total Attempts for current question: ${attemptCount}`);
    console.log(`Earned Points: ${earnedPoints}, New Points: ${newPoints}`);

    try {
      await updatePoints(userId, newPoints);
      setPoints(newPoints);
    } catch (error) {
      console.error('Failed to update points:', error);
    }

    onNextQuestion();
    setIsCorrect(null);
    setSelectedAnswer("");
    setAttemptCount(0);
    setShowNotification(false);
  };

  const closeNotification = () => {
    setShowNotification(false);
    if (isCorrect) {
      handleNextQuestion();
    } else {
      setIsCorrect(null);
      setSelectedAnswer("");
    }
  };

  return (
    <div className="question-container">
      <div className="question-title"><p>{question.question}</p></div>
      <div className="answers flex flex-col items-center justify-center w-full">
        {answers.map((answer, index) => (
          <button
            key={index}
            className={`answer-btn ${selectedAnswer === answer ? "selected" : ""}`}
            onClick={() => setSelectedAnswer(answer)}
            disabled={isCorrect !== null}
          >
            {answer}
          </button>
        ))}
      </div>
      {isCorrect === null && (
        <button className="check-btn" onClick={handleCheckAnswer} disabled={!selectedAnswer}>
          Submit
        </button>
      )}
      {showNotification && notificationType === 'success' && (
        <SuccessNotification
          text={notificationText}
          points={earnedPoints}
          duration={3000}
          onClose={closeNotification}
        />
      )}
      {showNotification && notificationType === 'failure' && (
        <FailureNotification
          text={notificationText}
          onClose={closeNotification}
        />
      )}
    </div>
  );
};

export default MCQQuestion;
