import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from './assets/rubear.png';
import { authActions } from '../../store/authSlice';
import { fetchPoints } from '../../services/users.service';
import { LEVEL_POINTS } from '../../utils/constants';
import { badgeImages } from '../../utils/imageLoader'; 

const getBadgeImage = (level) => {
  return badgeImages[level];
};

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userId = useSelector((state) => state.auth.user.userId); 
  const [points, setPoints] = useState(0);
  const [level, setLevel] = useState(1);
  const [progress, setProgress] = useState(0);

  const getUserPoints = async () => {
    try {
      console.log('Fetching points for user:', userId);
      const data = await fetchPoints(userId);
      console.log('Fetched data:', data);

      setPoints(data.level);

      const userLevel = LEVEL_POINTS.reduce((acc, curr) => (data.level >= curr.points ? curr.level : acc), 1);
      setLevel(userLevel);

      // Calculate progress to next level
      const nextLevelPoints = LEVEL_POINTS.find(l => l.level === userLevel + 1)?.points || 0;
      const progressToNextLevel = ((data.level - (LEVEL_POINTS.find(l => l.level === userLevel)?.points || 0)) / (nextLevelPoints - (LEVEL_POINTS.find(l => l.level === userLevel)?.points || 0))) * 100;
      setProgress(progressToNextLevel);

    } catch (error) {
      console.error('Failed to fetch user points:', error);
    }
  };

  useEffect(() => {
    if (isLoggedIn && userId) {
      getUserPoints();

      // Set up an interval to refresh every 20 seconds
      const intervalId = setInterval(() => {
        getUserPoints();
      }, 20000);

      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn, userId]);

  const handleLogout = () => {
    dispatch(authActions.logout());
    navigate('/login');
  };

  return (
    <nav className="navStyle">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="Yunicorn Logo" className="max-h-20" />
          </Link>
          <Link to="/" className="pl-4">
            {isLoggedIn && (
              <button onClick={() => navigate('/')} className="logout-btn">
                Home
              </button>
            )}
          </Link>
        </div>
        {isLoggedIn && (
          <div className="flex items-center justify-center relative">
            <div className="badge-progress-wrapper">
              {getBadgeImage(level) && (
                <img src={getBadgeImage(level)} alt={`Level ${level}`} className="level-badge" />
              )}
              <div className="progressbar_container">
                <div className="progressbar_fill" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
          </div>
        )}
        {isLoggedIn && (
          <div className="flex items-center">
            <button onClick={handleLogout} className="logout-btn">
              Logout
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
