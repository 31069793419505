import { configureStore, combineReducers } from '@reduxjs/toolkit';
//import storageSession from 'redux-persist/lib/storage/session';
import authSlice from './authSlice';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import activitySlice from './activitySlice';

const reducers = combineReducers({
  auth: authSlice.reducer,
  activity: activitySlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
