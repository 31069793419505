import { Routes, Route } from 'react-router-dom';

// import DashBoardViewPage from '../pages/DashboardPage';
import LoginPage from '../pages/LoginPage';
import StoriesPage from '../pages/DashboardPage';
import QuestionsPage from '../pages/QuestionsPage';
import TutorialPage from '../pages/TutorialPage';
import CharactersPage from '../pages/CharacterPage';
import SubjectsPage from '../pages/QuestionTypePage';
import DifficultyLevelPage from '../pages/QuestionDifficultyPage';


function Navigator() {
  return (
    <Routes>
    <Route path="/login" exact element={<LoginPage />} />
    <Route path="/" exact element={<StoriesPage />} />
    <Route path="/questions/:storyId" element={<QuestionsPage />} />
    <Route path="/tutorial" exact element={<TutorialPage />} />
    <Route path="/collection/:storyId" exact element={<CharactersPage />} />
    <Route path="/subject" exact element={<SubjectsPage />} />
    <Route path="/level" exact element={<DifficultyLevelPage />} />
    </Routes>
  );
}

export default Navigator;
