import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DifficultyLevelPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state => state.auth.isLoggedIn));

  if (!isLoggedIn) {
    navigate('/login');
  }

  const levels = [
    { name: 'Hard', id: 'hard' },
    { name: 'Easy', id: 'easy' }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <h2 className="headers">Select Difficulty Level</h2>
      <div className="grid grid-cols-2 gap-4 max-w-4xl mx-auto items-center justify-center">
        {levels.map(level => (
          <div key={level.id} className="check-btn-story">
            {level.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DifficultyLevelPage;
