export const evaluateAnswer = async (evaluationDetails) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/evaluate-answer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(evaluationDetails)
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to evaluate answer');
    }
    return { success: true, data };
  } catch (error) {
    console.error("Error in evaluateAnswer:", error);
    return { success: false, message: error.message || 'Failed to evaluate answer' };
  }
};
