import React from 'react';
import { useForm } from 'react-hook-form';
import { login } from '../../services/users.service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/authSlice';
import { useNavigate } from 'react-router-dom';


const LoginPage = () => {

  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // Function to handle login button click
  const handleLogin = async (data) => {
    const credentials = await login(data);

    if (credentials.user) {
      dispatch(authActions.login({ data: credentials.user }));
      toast.success("User login successful!");
      if (credentials.user.tutorial_flag) {
        navigate('/');
      } else {
        navigate('/tutorial');
      }
    }
  };


  return (
    <div className="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit((data) => handleLogin(data))}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">Username</label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Username"
                {
                ...register('username')
                }
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm mt-4"
                placeholder="Password"
                {
                ...register('password')
                }
              />
            </div>
          </div>

          <div className="flex justify-center">
          <button type="submit" className='check-btn'>Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
