import '../src/style.css'; // Importing global stylesheet

import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/common-ui/Header';
import Navigator from './routes';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Router>
      <Header />
      <Navigator />
      <ToastContainer />
    </Router>
  );
}

export default App;
