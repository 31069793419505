export const createOrUpdateResponse = async (responseDetails) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/student/count`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(responseDetails)
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || 'Failed to create or update response');
    }
    return { success: true, data }; 
  } catch (error) {
    console.error("Error in createOrUpdateResponse:", error);
    return { success: false, message: error.message || 'Failed to create or update response' }; 
  }
};

export const getCountByUserAndStory = async (userId, storyId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/yuni/student/counts/${userId}/${storyId}`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch questions');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};

