import axios from 'axios';
import { toast } from 'react-toastify';

export const login = async (data) => {
  return await axios
    .post(`${process.env.REACT_APP_BASE_URL}/yuni/login`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => toast.error('User not found!'));
};

export const fetchUsers = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/users`);
      const data = response.json();
        return data;
};

export const updateTutorialFlag = async (userId, flagStatus) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/users/${userId}/tutorial-flag`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ tutorial_flag: flagStatus })
    });

    const data = await response.json(); 
    if (!response.ok) {
      throw new Error(data.message || "Failed to update tutorial flag");
    }
    return { success: true, data };
  } catch (error) {
    console.error("Error in updateTutorialFlag:", error);
    return { success: false, message: error.message || "An error occurred" };
  }
};

export const fetchTutorialFlag = async (userId) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/users/${userId}/tutorial-flag`);
  const data = await response.json();
  return data;
};

export const fetchPoints = async (userId) => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/users/level/${userId}`);
  const data = await response.json();
  return data;
};

export const updatePoints = async (userId, points) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/users/levelUpdate/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ level: points })
    });

    const data = await response.json(); 
    if (!response.ok) {
      throw new Error(data.message || "Failed to update points/level");
    }
    return { success: true, data };
  } catch (error) {
    console.error("Error in updatePoints:", error);
    return { success: false, message: error.message || "An error occurred" };
  }
};