import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateTutorialFlag } from '../../services/users.service'; 
import img1 from '../../components/common-ui/assets/tutorial/1.png';
import img2 from '../../components/common-ui/assets/tutorial/2.png';
import img3 from '../../components/common-ui/assets/tutorial/3.png';
import img4 from '../../components/common-ui/assets/tutorial/4.png';
import { useSelector } from 'react-redux';

const TutorialPage = () => {
  const userId = useSelector((state) => state.auth.user.userId);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [img1, img2, img3, img4];
  const navigate = useNavigate(); 

 const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  if(!isLoggedIn){
    navigate('/login');
  }
  
  const handleNextClick = async () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else {
      // Update the tutorial flag when the last image is reached
      const updateResponse = await updateTutorialFlag(userId, true);
      if (updateResponse.success) {
        navigate('/'); 
      } else {
        console.error('Failed to update tutorial flag:', updateResponse.message);
        alert('Failed to complete tutorial. Please try again.'); 
      }
    }
  };

  return (
    <div className="tutorial-container">
      <h2 className="text-3xl font-extrabold text-gray-900 mb-8 text-center">Tutorial</h2>
      <div className="image-box">
        <img src={images[currentImageIndex]} alt={`Slide ${currentImageIndex + 1}`} />
      </div>
      <button className='check-btn' onClick={handleNextClick}>Next</button>
    </div>
  );
};

export default TutorialPage;
