import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SubjectsPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state => state.auth.isLoggedIn));
  
  if (!isLoggedIn) {
    navigate('/login');
  }

  const subjects = [
    { name: 'English', id: 'english' },
    { name: 'Maths', id: 'maths' },
    { name: 'Chinese', id: 'chinese' }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <h2 className="headers">Select a Subject</h2>
      <div className="grid grid-cols-3 gap-4 max-w-6xl mx-auto items-center justify-center">
        {subjects.map(subject => (
          <div key={subject.id} className="check-btn-story">
            {subject.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubjectsPage;
