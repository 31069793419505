export const getCharactersByStoryId = async (storyId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/yuni/student/characters/story/${storyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Failed to retrieve characters");
    }
    return { success: true, characters: data.characters };
  } catch (error) {
    console.error("Error in getCharactersByStoryId:", error);
    return { success: false, message: error.message || "An error occurred" };
  }
};
