// utils/levelBadges.js

import badge01 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-01.png';
import badge02 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-02.png';
import badge03 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-03.png';
import badge04 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-04.png';
import badge05 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-05.png';
import badge06 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-06.png';
import badge07 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-07.png';
import badge08 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-08.png';
import badge09 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-09.png';
import badge10 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-10.png';
import badge11 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-11.png';
import badge12 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-12.png';
import badge13 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-13.png';
import badge14 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-14.png';
import badge15 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-15.png';
import badge16 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-16.png';
import badge17 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-17.png';
import badge18 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-18.png';
import badge19 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-19.png';
import badge20 from '../components/common-ui/assets/LevelsBadge/yuni-badge-level-20.png';

export const badgeImages = {
  1: badge01,
  2: badge02,
  3: badge03,
  4: badge04,
  5: badge05,
  6: badge06,
  7: badge07,
  8: badge08,
  9: badge09,
  10: badge10,
  11: badge11,
  12: badge12,
  13: badge13,
  14: badge14,
  15: badge15,
  16: badge16,
  17: badge17,
  18: badge18,
  19: badge19,
  20: badge20
};
