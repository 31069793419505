// SuccessModal.js
import React from 'react';
import Modal from 'react-modal';

const SuccessModal = ({ isOpen, onRequestClose, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Success Notification"
      className="modal-content"
      overlayClassName="modal-overlay"
    >

      <div className="popup-message">{message}</div>
      <button onClick={onRequestClose} className=" modal-button generic-modal-btn">Okay</button>
    </Modal>
  );
};

export default SuccessModal;
