import { createSlice } from '@reduxjs/toolkit';

const activitySlice = createSlice({
  name: 'activity',
  initialState: {
    levelPoints: 0,
  },
  reducers: {
    setLevelPoints(state, action) {
      state.levelPoints = action.payload.data;
    },
  },
});

export const activityActions = activitySlice.actions;

export default activitySlice;
