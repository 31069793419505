// questions.service.js
const apiUrl = (`${process.env.REACT_APP_BASE_URL}/yuni/questions`);
;

export const fetchQuestions = async () => {
  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch questions');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};

export const fetchQuestionsByStoryId = async (storyId) => {
  const url = `${apiUrl}/${storyId}`; 
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Failed to fetch questions by storyId');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching questions by storyId:', error);
    throw error;
  }
};
