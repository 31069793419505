import React, { useState, useEffect } from 'react';
import { fetchStories } from '../../services/stories.service';
import StoryCard from './storyCard';
import { useSelector } from 'react-redux'; 
import { useNavigate } from 'react-router-dom';

const StoriesPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  if(!isLoggedIn){
    navigate('/login');
  }
  const [stories, setStories] = useState([]);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const storiesData = await fetchStories();
        setStories(storiesData);
      } catch (error) {
        console.error('Error fetching stories:', error);
      }
    };

    fetchDataFromApi();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <h2 className="headers">Select a Story</h2>
      <div className="grid grid-cols-2 gap-4 max-w-6xl mx-auto items-center justify-center">
        {stories.map(story => (
          <StoryCard key={story._id} story={story} />
        ))}
      </div>
    </div>
  );
};

export default StoriesPage;
