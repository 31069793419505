// SuccessNotification.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bearImage from './assets/modal/rubear-happy.png';

const SuccessNotification = ({ text, duration, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  if (!visible) return null;

  return (
    <div className="notification-wrapper">
      <div className="notification-container success">
        <div className="notification-content">
          <img src={bearImage} alt="Bear" className="notification-image" />
          <span className="notification-text">{text}</span>
        </div>
      </div>
      <button className="modal-button success-btn" onClick={onClose}>Next Question</button>
    </div>
  );
};


SuccessNotification.propTypes = {
  text: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SuccessNotification;
