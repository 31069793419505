import React from 'react';
import PropTypes from 'prop-types';
import bearImage from './assets/modal/rubear-sad.png';

const FailureNotificationWithOptions = ({ text, onRetry, onSubmit }) => {
  return (
    <div className="notification-wrapper">
      <div className="notification-container failure">
        <div className="notification-content">
          <img src={bearImage} alt="Bear" className="notification-image" />
          <span className="notification-text">{text}</span>
        </div>
        </div>
        <div className="notification-buttons">
          <button className="modal-button success-btn" onClick={onRetry}>Retry</button>
          <button className="modal-button failure-btn" onClick={onSubmit}>Submit</button>
        </div>
      </div>
  );
};


FailureNotificationWithOptions.propTypes = {
  text: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FailureNotificationWithOptions;
