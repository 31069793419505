import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchQuestionsByStoryId } from '../../services/questions.service';
import OpenEndedQuestion from './OpenEndedQuestion';
import MCQQuestion from './MCQQuestion';
import SuccessModal from '../../components/common-ui/SuccessModal';
import CharacterModal from '../../components/common-ui/CharacterModal';
import { useSelector } from 'react-redux';
import { createOrUpdateResponse, getCountByUserAndStory } from '../../services/questioncount.service';
import { fetchPoints, updatePoints } from '../../services/users.service'; 
import { POINTS_PER_TEN_QUESTIONS } from '../../utils/constants';

const QuestionsPage = () => {
  const user = useSelector((state) => state.auth.user);
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [showCharacterModal, setShowCharacterModal] = useState(false);
  const [points, setPoints] = useState(0);
  const { storyId } = useParams();
  const navigate = useNavigate();
  
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  if (!isLoggedIn) {
    navigate('/login');
  }

  // useEffect to fetch the questions (run only once when the component mounts or the storyId changes)
  useEffect(() => {
    const getQuestions = async () => {
      try {
        const fetchedQuestions = await fetchQuestionsByStoryId(storyId);
        setQuestions(fetchedQuestions);
      } catch (error) {
        console.error('Failed to fetch questions:', error);
        setQuestions([]);
      }
    };

    const getInitalCount = async () => {
      try {
        const response = await getCountByUserAndStory(user.userId, storyId);
        setCurrentIndex(response.count);
      } catch (error) {
        console.error('Failed to fetch initial count:', error);
      }
    };

    const getUserPoints = async () => {
      try {
        const data = await fetchPoints(user.userId);
        setPoints(data.level);
      } catch (error) {
        console.error('Failed to fetch user points:', error);
      }
    };

    getQuestions();
    getInitalCount();
    getUserPoints();
  }, [storyId, user.userId]);  // Dependency array ensures this only runs when storyId or userId changes

  // Handle when the user clicks next question
  const handleNextQuestion = async () => {
    const newIndex = currentIndex + 1;

    // Ensure the question is not saved multiple times
    if (newIndex < questions.length) {
      setCurrentIndex(newIndex);
      try {
        // Save the user's response for the question
        await createOrUpdateResponse({ userId: user.userId, storyId, count: newIndex });

        if (newIndex % 10 === 0) {
          const newPoints = points + POINTS_PER_TEN_QUESTIONS;
          try {
            await updatePoints(user.userId, newPoints);  // Update points only once every 10 questions
            setPoints(newPoints);
          } catch (error) {
            console.error('Failed to update points:', error);
          }

          setShowCharacterModal(true);
        }
      } catch (error) {
        console.error('Failed to update count:', error);
      }
    } else {
      setShowCompletionModal(true);
    }
  };

  const closeCompletionModal = () => {
    setShowCompletionModal(false);
    navigate('/');
  };

  const closeCharacterModal = () => {
    setShowCharacterModal(false);
  };

  // Function to calculate progress through the questions
  const getCurrentProgress = () => {
    const currentSetStart = Math.floor(currentIndex / 10) * 10;
    const questionsInCurrentSet = questions.slice(currentSetStart, currentSetStart + 10);
    return questionsInCurrentSet.length > 0 ? ((currentIndex % 10) + 1) / questionsInCurrentSet.length * 100 : 0;
  };

  const progressPercentage = getCurrentProgress();

  return (
    <div className="p-4">
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
      </div>
      {questions && questions[currentIndex] ? (
        questions[currentIndex].questionType === "Open-ended" ? (
          <OpenEndedQuestion 
            userId={user.userId} 
            question={questions[currentIndex]} 
            onNextQuestion={handleNextQuestion} 
            storyId={storyId} 
          />
        ) : (
          <MCQQuestion 
            userId={user.userId} 
            question={questions[currentIndex]} 
            onNextQuestion={handleNextQuestion} 
          />
        )
      ) : (
        <p>Loading questions...</p>
      )}
      {showCompletionModal && (
        <SuccessModal 
          isOpen={true} 
          onRequestClose={closeCompletionModal} 
          message="You've completed all the questions!" 
        />
      )}
      {showCharacterModal && (
        <CharacterModal
          isOpen={true}
          onRequestClose={closeCharacterModal}
          storyId={storyId}
          count={currentIndex}
        />
      )}
    </div>
  );
};

export default QuestionsPage;
