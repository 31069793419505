import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCharactersByStoryId } from '../../services/character.service';
import { getCountByUserAndStory } from '../../services/questioncount.service';
import Modal from '../../components/common-ui/Modal';

const CharactersPage = () => {
  const { storyId } = useParams();
  const user = useSelector(state => state.auth.user);
  const [characters, setCharacters] = useState([]);
  const [error, setError] = useState('');
  const [count, setCount] = useState(0);
  const [showModal, setShowModal] = useState(true);

  const navigate = useNavigate();
  
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  if(!isLoggedIn){
    navigate('/login');
  }

  useEffect(() => {
    const fetchCharacters = async () => {
      const result = await getCharactersByStoryId(storyId);
      if (result.success) {
        setCharacters(result.characters);
      } else {
        setError(result.message);
      }
    };

    const fetchCount = async () => {
        try {
        const response = await getCountByUserAndStory(user.userId, storyId);
        console.log(response);
        setCount(response.count);
      } catch (error) {
        console.error('Failed to fetch initial count:', error);
      }
    }    

    fetchCharacters();
    fetchCount();
  }, [storyId, user.userId]);

  const getSanitizedCharacterName = (name) => {
    return name.toLowerCase();
  };

  const getImageUrl = (characterName) => {
    const sanitizedCharacterName = getSanitizedCharacterName(characterName);
    const basePath = storyId === '653e087da69abc09ffabca5a' ? 'kings_cake' :
                     storyId === '652e66b3a11c62a7a4b89a8d' ? 'lazy_duck' :
                     'default';
    return `https://bsc2fast.github.io/hopu/images/${basePath}/${sanitizedCharacterName}.png`;
  };

  const heading = useMemo(() => {
    switch (storyId) {
      case '653e087da69abc09ffabca5a': return 'The King\'s Cake Characters';
      case '652e66b3a11c62a7a4b89a8d': return 'Lazy Duck Characters';
      default: return 'Characters';
    }
  }, [storyId]);

  const unlockedCharacters = Math.floor(count / 10); 
   const progressPercentage = characters.length > 0 ? (unlockedCharacters / characters.length) * 100 : 0;



   const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="p-4">

    <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
      </div>

      <h2 className="headers">{heading}</h2>

      {error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : (
        <div className="grid grid-cols-3 gap-4 max-w-6xl mx-auto">
          {characters.map((character, index) => {
            const imageUrl = getImageUrl(character.characterName);
            const isUnlocked = index < unlockedCharacters;
            const imageClass = isUnlocked ? "max-w-full h-auto" : "max-w-full h-auto grayscale blur";
            return (
              <div key={`${character.characterName}-${index}`}>
                <img src={imageUrl} alt={character.characterName} className={imageClass} />
              </div>
            );
          })}
        </div>
      )}

      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={handleCloseModal}
          message="Answer questions to unlock more characters!"
        />
      )}

    </div>
  );
};

export default CharactersPage;
