// SuccessModal.js
import React from 'react';
import Modal from 'react-modal';
import imageSuccess from './assets/modal/checked.png'

const SuccessModal = ({ isOpen, onRequestClose, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Success Notification"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="flex justify-center">
         <img src={imageSuccess} alt="check icon" className="max-h-20" />
      </div>
      <div className="popup-message">{message}</div>
      <button onClick={onRequestClose} className=" modal-button success-btn">Okay</button>
    </Modal>
  );
};

export default SuccessModal;
